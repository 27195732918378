import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import Form from '../../js/Libraries/Form';
import jump from 'jump.js';
class Subscription extends Standard {
  constructor() {
    super('#subscription');

    if (!super.exist()) return;

    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.form = new Form();
    this.data = {
      firstName: false,
      lastName: false,
      email: false,
      message: false,
    };
    this.text_error = 0;
    this.sentBtn = super.set('#send_btn');
    this.email = super.set('#email');
    this.main_error = super.set('.main_error');
    this.inputs = super.set('input[type="text"]', true);
    this.endpoint = super.setEndpoint('pages_api/post_subscription');
    this.events();
  }
  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!this.form.check_valid_email(email)) {
      this.email.classList.add('error');
      this.data['email'] = false;
      this.text_error += 1;
      this.main_error.innerText = 'Please choose a valid Email Address!';
    } else {
      this.email.classList.remove('error');
      this.data['email'] = email;
    }
  }

  async sent() {
    this.checkEmail();
    if (super.contains(this.data, false)) {
      this.text_error += 1;
      this.main_error.innerText = 'Please fill in all fields';
      return;
    }

    this.hl.toggle();
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);

    await this.hl.wait();
    if (res.status === 200) {
      this.clean_input();
      //   this.hl.message('We have received your message and will answer you immediately!');
    } else {
      //   this.hl.message('There are problems with our server right now. Please try it later again.');
    }
    return this.hl.toggle();
  }

  clean_input() {
    this.inputs.forEach((each) => {
      each.value = '';
    });
  }
  events() {
    this.sentBtn.addEventListener('click', () => this.sent());
    this.inputs.forEach((each) => {
      each.addEventListener('focus', () => each.classList.remove('error'));
    });
    this.textarea.addEventListener('focus', () => this.textarea.classList.remove('error'));
  }
}

new Subscription();
